import { useEffect, useState } from "react";
import "./CarImage.css";
import { CarDto } from "./dto/car-dto";

function CarImage({ car }: { car: CarDto }) {
  const [src, setSrc] = useState<string>("");

  useEffect(() => {
    const firstSrc = car.photo_urls.length > 0 ? car.photo_urls[0] : "";

    if (!firstSrc) {
      return;
    }

    if (firstSrc.includes("https://")) {
      setSrc(firstSrc);
    } else {
      setSrc(`https://${firstSrc}`);
    }
  }, []);

  function noImageDiv(): JSX.Element {
    return <div className="carImage__noImage">Kein Bild</div>;
  }

  return (
    <div className="carImage">
      {src === "" ? (
        noImageDiv()
      ) : (
        <img
          className="carImage__img"
          src={src}
          alt={car.brand}
          loading="lazy"
        />
      )}
    </div>
  );
}

export default CarImage;
