import React from "react";
import "./Title.css";

function Title({ title }: { title: string }) {
  return (
    <div className="title">
      <h3 className="title__pageName">{title}</h3>
      <p className="title__version">v6.2.7</p>
    </div>
  );
}

export default Title;
