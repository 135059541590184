import React from "react";
import "./Url.css";

function Url({ url }: { url: string }) {
  const copyToMemory = (url: string) => {
    if (!window.isSecureContext) {
      const message = `1. Enter chrome://flags in your browser's address bar.
                       2. Search for Insecure origins treated as secure.
                       3. Enable the setting by clicking on the button to the right.
                       4. Type the origins that you want to enable the setting: http://makkizentral.de:4000`;
      alert(message);
      return;
    }

    navigator.clipboard.writeText(url);
  };

  return (
    <div className="url">
      <button className="url__copyButton" onClick={() => copyToMemory(url)}>
        Kopieren
      </button>
      <a className="url__link" href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
    </div>
  );
}

export default Url;
