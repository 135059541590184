import React from "react";
import "./Parser.css";
import axios from "axios";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import Navbar from "./Navbar";
import Title from "./Title";

function Parser() {
  const REACT_APP_CENTRAL_API_URL =
    process.env.REACT_APP_CENTRAL_API_URL || "http://localhost:3000/api/parser";

  const parserApiUrl = REACT_APP_CENTRAL_API_URL + "/parse-not-emit";
  const [error, setError] = React.useState<string>("");
  const [isError, setIsError] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<string>("");
  const [processing, setProcessing] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const onParserClick = () => {
    const input = inputRef.current?.value;
    if (input) {
      setResult("");
      setProcessing(true);
      setIsError(false);
      setIsError(false);
      axios
        .post(parserApiUrl, {
          url: input,
        })
        .then((res) => {
          setError("");
          setResult(res.data);
          setIsError(false);
          setProcessing(false);
        })
        .catch((err) => {
          setResult("");
          setError(err.response.data);
          setIsError(true);
          setProcessing(false);
        });
    }
  };

  //   Create arrow function that returns result of the parser or error

  const getParsedData = (): string => {
    if (result) {
      return result;
    }

    if (error) {
      return error;
    }

    return "";
  };

  return (
    <div className="parser">
      {/* Create input for URL and the button left*/}
      <Title title="Parser" />
      <div className="parser__input">
        <button className="parser__btnSend" onClick={onParserClick}>
          Senden
        </button>
        <input type="text" className="parser__urlInput" ref={inputRef} />
      </div>

      <div className="parser__result">
        {/* Display the result of the parser */}
        {getParsedData() && (
          <JsonView
            data={getParsedData()}
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        )}
        {isError && <div className="parser__error"></div>}
        {processing && <div className="parser__processing"></div>}
      </div>

      <Navbar />
    </div>
  );
}

export default Parser;
