import { useState } from "react";
import "./Countdown.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const renderTime = ({ remainingTime }: { remainingTime: number }) => {
  if (remainingTime === 0) {
    return null;
  }

  return (
    <div className="countdown__timer">
      <div className="countdown__value">{remainingTime}</div>
    </div>
  );
};

function Countdown({ duration }: { duration: number } = { duration: 0 }) {
  const [className, setClassName] = useState("countdown");

  if (!duration) {
    return <div></div>;
  }

  if (duration === 0) {
    return <div></div>;
  }

  return (
    <div className={className}>
      <CountdownCircleTimer
        isPlaying
        duration={duration}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[10, 6, 3, 0]}
        onComplete={() => setClassName("countdown--inactive")}
        size={35}
        strokeWidth={5}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
}

export default Countdown;
