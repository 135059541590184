import React, { lazy } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import EnginePower from "./EnginePower";
import Parser from "./Parser";
import GoodPrices from "./GoodPrices";
import Dataflow from "./Dataflow";
import LoginPage from "./LoginPage";
import AuthProvider from "./provider/AuthContext";
import Routes from "./routes";

export interface IApplicationProps {}

const Main = lazy(() => import("./Main"));

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Main />} />
    //     <Route path="/login" element={<LoginPage />} />
    //     <Route path="/good-prices" element={<GoodPrices />} />
    //     <Route path="/engine-power" element={<EnginePower />} />
    //     <Route path="/parser" element={<Parser />} />
    //     <Route path="/dataflow" element={<Dataflow />} />
    //   </Routes>
    // </BrowserRouter>

    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
};

export default Application;
