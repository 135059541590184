import React, { useState, useContext } from "react";
import "./LoginPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./provider/AuthContext";

const api = process.env.REACT_APP_CENTRAL_API || "http://localhost:3000";

const LoginPage = () => {
  const { setToken, setRefreshToken } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [loginError, setLoginError] = useState("");

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${api}/auth/login`, formData);

      if (response.status === 200) {
        setToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      setLoginError(error.response.data?.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={formData.username}
          onChange={handleInputChange}
          className="login-input"
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          className="login-input"
        />
        <button type="submit" className="login-button">
          Login
        </button>
        {loginError && <div className="login-error">{loginError}</div>}
      </form>
    </div>
  );
};

export default LoginPage;
