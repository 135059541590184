import "./CarTile.css";
import mobile from "./img/mobile.png";
import PriceRating from "./PriceRating";
import { CarDto } from "./dto/car-dto";
import kleinanzeigen from "./img/kleinanzeigen.png";
import autoscout from "./img/autoscout24.png";
import cardeluxe from "./img/cardeluxe.png";
import React from "react";
import PriceHistory from "./PriceHistory";
import SimpleCountdown from "./SimpleCountdown";
import Countdown from "./Countdown";
import {
  JsonView,
  allExpanded,
  collapseAllNested,
  defaultStyles,
} from "react-json-view-lite";
import Url from "./Url";
import BrandAndModel from "./BrandAndModel";
import CarImage from "./CarImage";

interface CarTileProps {
  car: CarDto;
  isDuplicate: boolean;
  deleteCar?: (id: number) => void;
}

function CarTile({ car, isDuplicate, deleteCar }: CarTileProps) {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  function getPlatformLogo(url: string): string | undefined {
    if (!url) {
      return;
    }

    if (url.includes("kleinanzeigen")) {
      return kleinanzeigen;
    }

    if (url.includes("hs-preview")) {
      return cardeluxe;
    }

    if (url.includes("mobile")) {
      return mobile;
    }

    if (url.includes("autoscout")) {
      return autoscout;
    }
  }

  function openCar(): React.MouseEventHandler<HTMLButtonElement> | undefined {
    // Create a popup with the car
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const popup = window.open(
        "",
        "popup",
        "width=600,height=600,scrollbars=no,resizable=no"
      );
      if (popup) {
        popup.document.write(`<pre>${JSON.stringify(car, null, 2)}</pre>`);
      }
    };
  }

  function expandCarData() {
    setIsExpanded(!isExpanded);
  }

  const createPriceRating = (car: CarDto) => {
    if (!car.price_rating) {
      return null;
    }

    return <PriceRating price_rating={car.price_rating} />;
  };

  const className = isDuplicate ? "tile__car--duplicate" : "tile__car";

  function createAdState(isAdNew: boolean): React.ReactNode {
    if (isAdNew) {
      return <span className="tile_newCarState">neu</span>;
    }

    return null;
  }

  function createCarAccident(hasCarAccident: boolean): React.ReactNode {
    if (hasCarAccident) {
      return <span className="tile_carAccident">Unfall</span>;
    }

    return null;
  }

  return (
    <div className={className}>
      <div className="tile__firstLine">
        <div className="tile__firstLineLeft">
          {createCarAccident(car.has_car_accident)}
          {createAdState(car.isAdNew)}
          {car.title}
        </div>
        <div className="tile__firstLineRight">
          {deleteCar && (
            <button onClick={() => deleteCar(car.id)}>Löschen ❌</button>
          )}
          {car.formated_time}
        </div>
      </div>

      <div className="tile__carImage">
        <CarImage car={car} />

        <div className="tile__urls">
          <Url url={car.url} />

          <Url url={car.searchUrl} />
        </div>
      </div>

      <div className="countDownWithRating">
        {/* <SimpleCountdown duration={car.count_down} /> */}
        <Countdown duration={car.count_down} />
        {createPriceRating(car)}
      </div>

      {/* Find car -> price, engine power */}
      <div className="tile__pricesAndEnginepower">
        <div className="tile__carCurrentPrice">{car.price}</div>
        <PriceHistory priceHistory={car.price_history} />
        <div className="tile__comparePrices">
          {car?.prices?.map((price, index) => (
            <div key={index} className="tile__comparePrice">
              {price.price}
            </div>
          ))}
        </div>

        <BrandAndModel car={car} />
      </div>

      <div className="tile__carPlatform">
        <button onClick={openCar()}>Auto</button>
        <button onClick={expandCarData}>
          {isExpanded ? "Zuklappen" : "Aufklappen"}
        </button>
        <img src={getPlatformLogo(car.source)} alt="platform" />
      </div>
      <div className="tile__jasonView">
        {isExpanded && (
          <JsonView
            data={car}
            shouldExpandNode={collapseAllNested}
            style={defaultStyles}
          />
        )}
      </div>
    </div>
  );
}

export default CarTile;
