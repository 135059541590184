import "./PriceHistory.css";
import { PriceHistoryType } from "./dto/car-dto";

function PriceHistory({ priceHistory }: { priceHistory: PriceHistoryType[] }) {
  function hasPriceHistory(): boolean {
    return priceHistory && priceHistory.length > 1;
  }

  function getPreviousPrice(): JSX.Element {
    return (
      // <div className="priceHistory__previousPrice">
      //   {priceHistory[priceHistory.length - 1].price}
      // </div>
      // display previous prices, max 3 prices in a row
      <div className="priceHistory__previousPrices">
        {priceHistory.slice(1, 4).map((price, index) => (
          <div key={index} className="priceHistory__previousPrice">
            {price.price}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="priceHistory">
      {hasPriceHistory() && getPreviousPrice()}
    </div>
  );
}

export default PriceHistory;
