import React from "react";
import "./PriceRating.css";

interface PriceRatingProps {
  rating: string | null;
  rating_reason: string | null;
}

const PriceRating = (props: { price_rating: PriceRatingProps | null }) => {
  const price_rating = props.price_rating;

  if (!price_rating) {
    return null;
  }

  const indicator = price_rating?.rating?.toUpperCase();
  const indicatorClassName = `car__priceRating indicator--${indicator}`;
  const ratingLabel = getLabelForPriceRating(price_rating.rating);
  const ratingReason = price_rating.rating_reason;

  function getLabelForPriceRating(price_rating: string | null): string {
    switch (price_rating) {
      case "WITHOUT_RATING":
        return "Ohne Bewertung";
      case "VERY_GOOD_PRICE":
        return "Sehr guter Preis";
      case "GOOD_PRICE":
        return "Guter Preis";
      case "REASONABLE_PRICE":
        return "Fairer Preis";
      case "INCREASED_PRICE":
        return "Erhöhter Preis";
      case "HIGH_PRICE":
        return "Hoher Preis";
      case "TOO_HIGH_PRICE":
        return "Preis zu teuer";
      case "TOO_LOW_PRICE":
        return "Preis zu niedrig";
      default:
        return "Ohne Bewertung";
    }
  }

  function openPopup() {
    if (!ratingReason) {
      return;
    }

    // Popup window in the middle of the screen
    const left = window.screen.width / 2 - 200;
    const top = window.screen.height / 2 - 200;
    const popup = window.open(
      "",
      "popup",
      `width=400,height=400,left=${left},top=${top}`
    );

    popup?.document.write(ratingReason);
  }

  return (
    <div className="car__priceRating">
      <div className={indicatorClassName}>
        <div className="indicator">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {ratingReason && (
            <svg
              className="indicator__infoIcon"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              onClick={openPopup}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 12.005C1.5 6.201 6.199 1.5 12 1.5s10.5 4.701 10.5 10.505C22.5 17.812 17.801 22.5 12 22.5S1.5 17.812 1.5 12.005zM11.975 9c.864 0 1.5-.686 1.5-1.475C13.475 6.661 12.839 6 12 6a1.53 1.53 0 00-1.525 1.525c0 .789.711 1.475 1.5 1.475zM15 18v-1c-1.311-.161-1.5-.276-1.5-1.702V10.5l-4 .5v1c1.15.184 1 .519 1 1.876v1.422c0 1.449-.758 1.541-2 1.702v1H15z"
                fill="currentColor"
              ></path>
            </svg>
          )}
        </div>
        <div className="car__priceRating--label">{ratingLabel}</div>
      </div>
    </div>
  );
};

export default PriceRating;
