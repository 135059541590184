import React from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { useGoodPricesCount } from "./GoodPrices";

function Navbar() {
  const navigate = useNavigate();
  const goodPricesCount = useGoodPricesCount();

  return (
    <div className="navbar">
      <div className="navbar__button" onClick={() => navigate("/")}>
        Hauptseite
      </div>
      <div className="navbar__button" onClick={() => navigate("/good-prices")}>
        Gute Preise
        <span className="navbar__buttonCount">{goodPricesCount}</span>
      </div>
      <div className="navbar__button" onClick={() => navigate("/parser")}>
        Parser
      </div>
      <div className="navbar__button" onClick={() => navigate("/dataflow")}>
        Datenfluss
      </div>
    </div>
  );
}

export default Navbar;
