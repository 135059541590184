import "./BrandAndModel.css";
import { CarDto } from "./dto/car-dto";

function BrandAndModel({ car }: { car: CarDto }) {
  return (
    <div className="brandAndModel">
      {car.brand ? (
        <div className="brand">{car.brand}</div>
      ) : (
        <div className="modelError">FEHLER</div>
      )}
      {car.model ? (
        <div className="model">{car.model}</div>
      ) : (
        <div className="modelError">FEHLER</div>
      )}
    </div>
  );
}

export default BrandAndModel;
