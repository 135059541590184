import { useEffect, useState } from "react";
import axios from "axios";

const CENTRAL_API =
  process.env.REACT_APP_CENTRAL_API || "http://localhost:3000/api";

type EmailNotificationToggleProps = {
  initialState: boolean;
};

const EmailNotificationToggle = ({
  initialState,
}: EmailNotificationToggleProps) => {
  const [isEnabled, setIsEnabled] = useState(initialState);

  // Sync local state with prop
  useEffect(() => {
    setIsEnabled(initialState);
  }, [initialState]);

  const handleToggle = async () => {
    const newStatus = !isEnabled;
    setIsEnabled(newStatus);

    const URL = `${CENTRAL_API}/email/toggle-search-url-notification`;

    try {
      await axios.post(URL, { enable: newStatus });
    } catch (error) {
      console.error("Failed to update notification preference", error);
    }
  };

  return (
    <div>
      <label>
        <input type="checkbox" checked={isEnabled} onChange={handleToggle} />
        E-Mail Benachrichtigungen für fehlende Vergleichslinks
      </label>
    </div>
  );
};

export default EmailNotificationToggle;
