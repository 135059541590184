import React, { useEffect, useState } from "react";

import "./EnginePower.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { v4 as uuid } from "uuid";

interface IEnginePower {
  title: string;
  brand: string;
  model: string;
  year: string;
  body_type: string;
  fuel_type: string;
  model_variant: string;
  engine_power: [];
}

export default function EnginePower() {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [enginePower, setEnginePower] = useState<IEnginePower>();
  const [error, setError] = useState<string>("");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      const { source } = state;

      if (source) {
        inputRef.current!.value = source;
      }
    }
  }, []);

  const PARSER_API_URL =
    process.env.REACT_APP_PARSER_API_URL ||
    "http://localhost:3000/api/parser/engine-power-by-url";

  const onEnginePowerClick = () => {
    const input = inputRef.current?.value;
    if (input) {
      axios
        .post(PARSER_API_URL, {
          input,
        })
        .then((res) => {
          setError("");
          setEnginePower(res.data);
        })
        .catch((err) => {
          setEnginePower(undefined);
          setError(err.response.data.message);
        });
    }
  };

  function getStatusClass(): string | undefined {
    if (enginePower) {
      return "enginePower__statusLine--success";
    } else if (error) {
      return "enginePower__statusLine--error";
    }
  }

  const displayWithBreaks = (text: string) => {
    return text.split("\n").map((str) => <p key={uuid()}>{str}</p>);
  };

  return (
    <div className="enginePower">
      <Link className="enginePower__toHome" to="/">
        <button className="main__enginePowerBtn">Home</button>
      </Link>
      <h1 className="enginePower__title">Engine Power 🐴</h1>

      <div className="enginePower__input">
        <button className="enginePower__inputBtn" onClick={onEnginePowerClick}>
          PS Prüfen
        </button>
        <input type="text" id="enginePower__input" ref={inputRef} />
      </div>

      <div className="enginePower__output">
        <div className={getStatusClass()}></div>
        {enginePower && (
          <div className="enginePower__outputResult">
            <p>
              <b>Titel: {enginePower?.title}</b>
            </p>
            <p>Marke: {enginePower?.brand}</p>
            <p>Modell: {enginePower?.model}</p>
            <p>Jahr: {enginePower?.year}</p>
            <p>Karosserie: {enginePower?.body_type}</p>
            <p>Kraftstoff: {enginePower?.fuel_type}</p>
            <p>Modellvariante: {enginePower?.model_variant}</p>
            <div className="enginePowerValues">
              PS:
              {enginePower?.engine_power.map((power) => (
                <div className="enginePowerValue">{power}</div>
              ))}
            </div>
          </div>
        )}

        {error && (
          <div className="enginePower__outputError">
            {displayWithBreaks(error)}
          </div>
        )}
      </div>
    </div>
  );
}
